import React from 'react';
import ReactMarkdown from 'react-markdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faEye } from '@fortawesome/free-solid-svg-icons';
import TextareaAutosize from 'react-textarea-autosize';

import './App.css';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      markdown: placeholder
    }

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({
      markdown: event.target.value
    })       
  }


  render() {
    const markdown = this.state.markdown;

    return(
      <div className="wrapper row justify-content-center">
        <div className="editor-container col-sm"> 
          <div className="row">
            <div className="header d-flex align-items-center justify-content-center d-inline" id="editor-header">
              <FontAwesomeIcon icon={faPencil} className="icon"/>
              <h2>Editor</h2>
            </div>
          </div>
          <div className="row">
            <Editor onChange={this.handleChange} markdown={markdown}/>
          </div>
        </div>
        
        <div className="previewer-container col-sm">
          <div className="row">
            <div className="header d-flex align-items-center justify-content-center" id="previewer-header">
              <FontAwesomeIcon icon={faEye} className="icon"/>
              <h2>Previewer</h2>
            </div>
          </div>
          <div className="row">
            <Previewer markdown={markdown} className="row" />
          </div>
        </div>
      </div>
    )
  }
}

const Previewer = (props) => {
  return(
    <div id="preview">
      <ReactMarkdown>
        {props.markdown}
      </ReactMarkdown>   
  </div>
  )
}

const Editor = (props) => {
  return(
    //the {props.onChange} and {props.markdown} are defining the props for the attributes
    <TextareaAutosize className="md-textarea form-control" rows="20" id="editor" onChange={props.onChange} value={props.markdown}/>
  )
}

const placeholder = 
`
# Welcome to my Markdown Previewer!
## This box will display the content you enter into the Editor.

**Check out the \`sum()\` function below. It returns the sum of two numbers.**
\`\`\`
function sum(num1, num2) {
  return num1 + num2;
}
\`\`\`

**There are three things you need to begin coding:**  
1. Access to a computer.
2. A love of learning!
3. The willingness to make mistakes (and grow from them!).

**Here's a couple insightful quotes about coding:**
> "Testing leads to failure, and failure leads to understanding." - Burt Rutan

> "In some ways, programming is like painting. You start with a blank canvas and certain basic raw materials. You use a
combination of science, art, and craft to determine what to do with them." - Andrew Hunt

![Person coding on MacBook Pro](https://images.unsplash.com/photo-1510751007277-36932aac9ebd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=737&q=80)
### *"Sorry, can't talk right now. I'm busy hacking into the mainframe."*

This React app was created for the Front End Development Libraries course on [FreeCodeCamp](https://www.freecodecamp.org/).
`

export default App;
